import axios from 'axios'

export default () => {
  return axios.create({
    // baseURL: `http://localhost/phpstormprojects/geonovation/beta/kaartviewer/admin/rest/kaartviewerapi`,
    baseURL: `../admin/rest/kaartviewerapi`,
    withCredentials: false,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
}
