<template>
    <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()"
              class="layout-navbar align-items-lg-center container-p-x pt-0 pb-0">

        <!-- Brand -->
        <b-navbar-brand to="/" class="p-0 mb-0 mt-0">
            <img :src="logo" alt="logo" height="46" style="max-height:56px;"/>
        </b-navbar-brand>

        <!-- Sidenav toggle -->
        <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">
            <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
                <i class="ion ion-md-menu text-large align-middle"></i>
            </a>
        </b-navbar-nav>

        <!-- Navbar toggle -->
        <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

        <b-collapse is-nav id="app-layout-navbar">

            <b-navbar-nav class="align-items-lg-center ">
                <!-- Search -->
                <label class="nav-item navbar-text navbar-search-box p-0 active">
                    <i class="ion ion-ios-search navbar-icon align-middle"></i>
                    <span class="navbar-search-input pl-2">
            <input type="text" class="form-control navbar-text mx-2" placeholder="Zoeken..." style="width:200px"
                   v-model="searchText">
          </span>
                </label>
            </b-navbar-nav>

            <b-navbar-nav class="align-items-lg-center ml-auto">
                <b-nav-item-dropdown no-caret class="demo-navbar-notifications mr-lg-3 dropdown-menu-right">
                    <template slot="button-content">
                        <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
                        <span class="badge badge-primary badge-dot indicator" v-if="showAnnouncementsIndicator"></span>
                        <span class="d-lg-none align-middle">&nbsp; Notificaties</span>
                    </template>

                    <div class="bg-danger text-center text-white font-weight-bold p-3"
                         v-bind:style="announcementsCount === 0 ? 'margin-bottom: -5px;margin-top: -5px;' : 'margin-top: -5px;' ">
                        <span v-if="announcementsCount === 0">
                          Geen nieuwe mededelingen
                        </span>
                        <span v-if="announcementsCount >= 1">
                          {{announcementsCount}} Nieuwe mededeling<span v-if="announcementsCount > 1">en</span>
                        </span>
                    </div>
                    <b-list-group flush>
                        <b-list-group-item class="media d-flex align-items-center"
                                           v-for="announcement in this.announcements" :key="announcement.created_at">
                            <div class="media-body line-height-condenced ml-3">
                                <div class="text-body">{{announcement.Data.Title}}</div>
                                <div class="text-light small mt-1 font-weight-bold" v-html="announcement.Data.Message">
                                </div>
                                <div class="text-light small mt-1">
                                    <i class="fa fa-clock"></i> {{ announcement.created_at }}
                                </div>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center p-0">
                            <div class="media-body line-height-condenced p-3" @click="showAnnouncements">
                                <div class="text-body text-center">Markeren als gelezen</div>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-nav-item-dropdown>

                <div class="nav-item d-none d-lg-block text-big line-height-1 opacity-25 mr-3 ml-1">|</div>

                <b-navbar-nav class="align-items-lg-center ml-auto"
                              v-if="user === null || user.Username === null || user.isIpAddress === 1">
                    <template v-if="user === null || user.ipAddress === undefined || user.ipAddress === null">
                        <b-btn variant="danger" @click="logIn" class="my-2 my-sm-0">Inloggen <i
                                class="fa fa-sign-in-alt"></i></b-btn>
                    </template>
                    <template v-if="user !== null && user.ipAddress !== undefined && user.ipAddress !== null">
                        <b-btn variant="danger"
                               v-bind:title="'Wie bent u? U bevindt zich in een vertrouwde omgeving ' + user.ipAddress.Username"
                               @click="logIn" class="my-2 my-sm-0">Inloggen
                            <i class="fa fa-sign-in-alt"></i></b-btn>
                    </template>
                </b-navbar-nav>

                <b-nav-item-dropdown ref="ddown" class="demo-navbar-user dropdown-menu-right show"
                                     v-if="user !== null && user.isIpAddress === 0">
                    <template slot="button-content">
                        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                            <span class="px-1 mr-lg-2 ml-2 ml-lg-0"
                                  v-if="user !== null && (user.ipAddress === undefined || user.ipAddress === null)"
                                  v-html="user.Username"></span>
                            <span class="px-1 mr-lg-2 ml-2 ml-lg-0"
                                  v-bind:title="'Wie bent u? U bevindt zich in een vertrouwde omgeving ' + user.ipAddress.Username"
                                  v-if="user !== null && user.ipAddress !== undefined && user.ipAddress !== null"
                                  v-html="user.Username"></span>

                            <i class="fa fa-user mr-1" v-if="user.isIpAddress === 0 && user.IsSsoUser === false"></i>
                            <i class="fab fa-windows mr-1" v-if="user.IsSsoUser === true"></i>
                        </span>
                    </template>
                    <b-dd-item @click="profile"><i class="ion ion-ios-person"></i> &nbsp;Mijn profiel
                    </b-dd-item>
                    <b-dd-item @click="settings"><i class="ion ion-md-settings"></i> &nbsp; Account
                        instellingen
                    </b-dd-item>
                    <b-dd-item @click="admin" v-if="user.IsAdmin === true">
                        <i class="fa fa-wrench"></i>
                        KaartViewer Admin
                    </b-dd-item>
                    <b-dd-divider v-if="user.IsAdmin === true"/>

                  <b-dd-header v-if="user.sessions.length > 0">Sessies</b-dd-header>

<!--                  <b-dd-item @click="openSession(session)" v-for="session in user.sessions" v-bind:key="session.ID">-->
<!--                    <i class="far fa-bookmark"></i> &nbsp; {{session.Name}}-->
<!--                  </b-dd-item>-->

<!--                  <b-dd variant="default" text="Nested dropdowns" v-for="bookmarkSession in user.sessions" v-bind:key="bookmarkSession.ID">-->


<!--                      <b-dd-item>First action</b-dd-item>-->

                      <div class="dropdown-toggle" v-for="bookmarkSession in user.sessions" v-bind:key="bookmarkSession.ID">
                        <b-dd-item>{{bookmarkSession.Naam}}</b-dd-item>
                        <div class="dropdown-menu">
                          <b-dd-item v-for="userSession in bookmarkSession.user_sessions" v-bind:key="userSession.ID" @click="openSession(userSession)"><i class="far fa-bookmark"></i> {{userSession.Name}}</b-dd-item>
                        </div>
                      </div>

                  <b-dd-divider v-if="user.sessions.length > 0"/>

                    <b-dd-item v-if="user.isIpAddress === 0" @click="logOut"><i
                            class="ion ion-ios-log-out text-danger"></i> &nbsp; Uitloggen
                    </b-dd-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>

        </b-collapse>

    </b-navbar>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import AuthService from "../services/AuthService";
    import MenuService from "../services/MenuService";
    import moment from 'moment'

    export default {
        name: 'app-layout-navbar',
        data: () => ({
            searchText: '',
            showAnnouncementsIndicator: false,
            user: false,
            announcements: [],
            announcementsCount: 0,
            logo: '../admin/rest/kaartviewerapi/menu/logo',
        }),
        props: {
            sidenavToggle: {
                type: Boolean,
                default: true
            }
        },
        created() {
            this.getLoggedInUser().then(() => {
                this.getAnnouncement();
                this.$parent.loadHomeMenu();
            });
        },
        watch: {
            searchText: function (val) {
                this.setSearchText(val)
            }
        },
        computed: {
            ...mapGetters([
                'getSearchText'
            ])
        },
        methods: {
            ...mapMutations([
                'setSearchText',
                'setFooterAnnouncement'
            ]),
            logIn() {
                location.replace('../admin/login?redirect=../menu/index.html');
            },
            logOut() {
                location.replace('../admin/logout?redirect=../menu/index.html');
            },
            profile() {
                window.open('../admin/kaartviewer/account/profile');
            },
            settings() {
                window.open('../admin/kaartviewer/account/settings');
            },
            admin() {
                window.open('../admin');
            },
            openSession(session) {
                window.open('../?@' + session.bookmark.Naam + '&session=' + session.Sorting);
            },
            toggleSidenav() {
                this.layoutHelpers.toggleCollapsed()
            },
            getLayoutNavbarBg() {
                return this.layoutNavbarBg
            },
            showAnnouncements() {
                localStorage.setItem('announcementsReadTime', new Date())
                this.showAnnouncementsIndicator = false
            },
            async getAnnouncement() {
                const response = await MenuService.announcement();

                const announcementsReadTime = moment(new Date(localStorage.getItem('announcementsReadTime')));

                response.data.announcement.map((announcement) => {
                    announcement.Data = JSON.parse(announcement.Data);
                    if (announcement.Status === 2) {
                        this.$store.commit('setFooterAnnouncement', announcement);
                    }
                    this.announcements.push(announcement);

                    // eslint-disable-next-line no-console
                    // eslint-disable-next-line no-console
                    const convertedDate = moment(announcement.created_at, 'DD MMM YYYY HH mm');
                    if (announcementsReadTime.diff(convertedDate, 'seconds') < 0) {
                        this.showAnnouncementsIndicator = true;
                    }
                });

                this.announcementsCount = this.announcements.length
            },
            async getLoggedInUser() {
                const uri = window.location.href.split('?');
                let queryParams = '';
                if (uri !== undefined && uri !== null && uri.length === 2) {
                    queryParams = '?' + uri[1] + '&time=' + new Date().getTime()
                } else {
                  queryParams = '?time=' + new Date().getTime()
                }

                const response = await AuthService.myself(queryParams)

                /* eslint-disable no-console */
                console.log('Check auth')
                console.log(response)
                console.log(response.status)

                if(typeof response.data === 'object' && response.data !== null) {
                    console.log('is object')

        if(response.data.AuthSource !== undefined && response.data.AuthSource !== null) {
          window.location.href = '../admin/rest/auth/myself?redirect=../menu/index.html&loginType=1&source=' + response.data.AuthSource
        }
                    
                    if (response.data.Username !== undefined) {
                      this.user = response.data
                    } else {
                      this.user = null
                    }
                    
                   
                } else {
                  const urlParams = new URLSearchParams(window.location.search);
                  const source = urlParams.get('source');
                  if(source !== undefined && source !== '') {
                     window.location.href = '../admin/rest/auth/myself?redirect=../menu/index.html&loginType=1&source=' + source
                  }
                }
            },
        },
    }
</script>
