import Vue from 'vue'
import App from './App'
import store from './store'
import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'
import { VueMasonryPlugin } from 'vue-masonry/index.js'
import './registerServiceWorker'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false
Vue.use(require('vue-moment'));
Vue.use(BootstrapVue)
Vue.use(VueMasonryPlugin)
// Global RTL flag
Vue.mixin({
  data: globals
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  components: { App },
  template: '<App/>',
  render: h => h(App)
})

