<template>
    <div class="layout-wrapper layout-1 layout-without-sidenav">
        <div class="layout-inner">
            <app-layout-navbar :sidenavToggle="false"/>
            <div class="layout-container">
                <div class="layout-content">
                    <div class="router-transitions container-fluid flex-grow-1 ui-bg-cover"
                         :style="{ 'background-image': `url('${background}')` }">
                        <Home ref="form"/>
                    </div>
                    <app-layout-footer/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LayoutNavbar from './LayoutNavbar'
    import LayoutFooter from './LayoutFooter'
    import Home from "../components/Home";

    export default {
        name: 'app-layout-without-sidenav',
        data: () => ({
            background: '../admin/rest/kaartviewerapi/menu/background',
        }),
        components: {
            Home,
            'app-layout-footer': LayoutFooter,
            'app-layout-navbar': LayoutNavbar
        },
        methods: {
            loadHomeMenu() {
                this.$refs.form.loadMenu()
            }
        },
        mounted() {
            // Enshure that we have not '.layout-expanded' class on the html element
            this.layoutHelpers._removeClass('layout-expanded')

            this.layoutHelpers.init();
            this.layoutHelpers.update();
            this.layoutHelpers.setAutoUpdate(true);
        },
        beforeDestroy() {
            this.layoutHelpers.destroy()
        },
    }
</script>

<style>
    .layout-content {
        margin-top: 0 !important;
    }
</style>
