import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store(
  {
    state: {
      menu: [],
      searchText: '',
      footerAnnouncement: null
    },
    getters: {
      getMenu: state => {
        return state.menu
      },
      getSearchText: state => {
        return state.searchText
      },
      getFooterAnnouncement: state => {
        return state.footerAnnouncement
      }
    },
    mutations: {
      setMenu (state, menu) {
        state.menu = menu
      },
      setFooterAnnouncement (state, footerAnnouncement) {
        state.footerAnnouncement = footerAnnouncement
      },
      setSearchText (state, searchText) {
        state.searchText = searchText
      }
    }
  }
)
