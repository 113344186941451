import axios from 'axios'

export default () => {
    const instance = axios.create({
        // baseURL: `http://localhost/phpstormprojects/geonovation/beta/kaartviewer/admin/rest/auth`,
        baseURL: `../admin/rest/auth`,
        withCredentials: false,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    instance.interceptors.response.use(function (response) {
        return response;
    }, () => {
        window.location = "../admin/rest/auth/myself?source=default-sp";
    });
    return instance;
}
