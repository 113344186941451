<template>
  <div id="app">
    <LayoutWithoutSidenav/>
  </div>
</template>

<style src="@/vendor/styles/bootstrap-material.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork-material.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-cotton-material.scss" lang="scss"></style>
<style src="@/vendor/styles/colors-material.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
  import LayoutWithoutSidenav from './layout/LayoutWithoutSidenav.vue'

  export default {
    name: 'app',
    components: {
      LayoutWithoutSidenav
    }
  }
</script>
