<template>
    <div style="border-radius:0 0 10px 10px;opacity:0.95;"
         class="layout-home"
         v-if="menuData.length > 0"
         v-bind:style="getFooterAnnouncement === null ? 'margin-bottom:0px !important' : '' "
    >
        <ul class="nav nav-tabs d-none d-sm-flex" style="background-color:#ccc;margin-top: 70px !important;">
            <li class="nav-item" v-for="menu in getMenu" :key="menu.ID">
                <a class="nav-link text-transform-none" @click.prevent="curTag = menu.ID"
                   :class="{'active': curTag=== menu.ID}" href="#">
                  <span v-html="menu.Tab">
                  </span>
                </a>
            </li>
        </ul>
        <div class="d-block d-sm-none">
            <select size="1" class="custom-select" @change="onChange()" v-model="selectedValue"
                    style="font-size:16px;">
                <option class="nav-item" v-for="menu in getMenu" :key="menu.ID" :value="menu.ID">
                    {{menu.Tab}}
                </option>
            </select>
        </div>
        <div class="pb-3 pt-3 bg-white">
            <div class="col-md-12">
                <small class="font-weight-bold" v-html="getSelectedMenu.Subtitel"></small>
            </div>
        </div>
        <div class="bg-white menu" v-masonry transition-duration="0s" style="opacity:1;">
            <div v-masonry-tile v-for="menuItem in getMenuItems" :key="menuItem.ID"
                 class="col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1" v-bind:title="menuItem.Tooltip">
                <b-card no-body class="mb-3 menu-item cursor-pointer" @click="redirectToMenuItem(menuItem)"
                        v-bind:style="{ 'background-color': menuItem.Kleur }">
                    <div class="w-100">
                        <a href="javascript:void(0)" class="card-img-top d-block ui-rect-60 ui-bg-cover"
                           :style="{ 'background-image': `url('${getBackground(menuItem)}')` }">
                            <div class="d-flex justify-content-between ui-rect-content pt-2 pr-2">
                                <div class="flex-shrink-1">
                                </div>
                                <div class="text-big" v-if="menuItem.menu_item_user_group !== undefined">
                                    <div class="badge badge-success font-weight-bold"
                                         v-if="menuItem.menu_item_user_group.length > 0">
                                        <i class="fa fa-lock"></i>
                                    </div>
                                    <div class="badge badge-danger font-weight-bold"
                                         v-if="menuItem.Anonymous === 0 && menuItem.menu_item_user_group.length === 0">
                                        <i class="fa fa-lock"></i>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="text-center text-menu-item pt-3 pb-1 "
                         v-bind:style="[ menuItem.Afbeelding === null || menuItem.Afbeelding === '' ? {'margin-top': '-25%', 'margin-bottom': '25%'} : '' ]">
                        <h6 class="mb-3">
                            <a href="javascript:void(0)" class="text-white"
                               style="font-size:10px;opacity:1;"
                               v-bind:style="{ 'color': menuItem.TextColor + ' !important' }" v-html="menuItem.Titel">
                            </a>
                        </h6>
                    </div>
                </b-card>
            </div>
            <div v-if="getMenuItems.length === 0 && this.curTag !== 10000000000000">
                <div class="alert alert-dark-warning cursor-pointer ml-2" v-on:click="clearSearchText">
                    <i class="fa fa-exclamation-triangle"></i> Geen bookmarks gevonden
                    <b-btn variant="warning" size="sm"><i class="fa fa-sync"></i></b-btn>
                </div>
            </div>
        </div>
        <div class="bg-white pl-2 pr-2 pb-2" v-if="this.curTag === 10000000000000">
            <ul class="nav row bg-lighter pl-4">
                <li class="nav-item"><a href="javascript:void(0)"
                                        class="nav-link text-body font-weight-bold pl-0">Alle</a></li>
                <li class="nav-item"><a href="javascript:void(0)" class="nav-link text-muted">Honden</a></li>
                <li class="nav-item"><a href="javascript:void(0)" class="nav-link text-muted">Losloopplekken</a></li>
                <li class="nav-item"><a href="javascript:void(0)" class="nav-link text-muted">Bomen</a></li>
                <li class="nav-item"><a href="javascript:void(0)" class="nav-link text-muted">Gemeente Hoorn</a></li>
                <li class="nav-item"><a href="javascript:void(0)" class="nav-link text-muted">Kunst</a></li>
                <li class="nav-item"><a href="javascript:void(0)" class="nav-link text-muted">Cultuur</a></li>
            </ul>
            <div class="ml-0 mr-0 row" style="opacity:1;">
                <div class="card col-md-2 col-12 mt-1" v-for="themeItem in getThemeItems"
                     :key="themeItem.ID"
                     style="box-shadow: 0 2px 3px 0 rgba(0,0,0,.03),0 1px 4px 0 rgba(0,0,0,.04),0 3px 1px -2px rgba(0,0,0,.04)">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item py-4 pl-0 pr-0">
                            <div class="media flex-wrap">
                                <div class="media-body">
                                    <h5 class="mb-2">
                                        <a href="javascript:void(0)" class="text-body" v-html="themeItem.Name"></a>&nbsp;
                                    </h5>
                                    <div class="d-flex flex-wrap align-items-center mb-2">
                                        <div>
                                            <button class="btn btn-xs btn-outline-danger mr-1"
                                                    v-for="bookmark in themeItem.Bookmark" v-bind:key="bookmark">
                                                <i class="fa fa-bookmark"></i> <span v-html="bookmark.Name"></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div v-html="themeItem.Description"></div>
                                    <div class="mt-2">
                                                <span class="badge badge-outline-default text-muted font-weight-normal mr-1"
                                                      v-for="tag in themeItem.Tags" v-bind:key="tag">
                                                    <span v-html="tag"></span>
                                                </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import MenuService from '../services/MenuService'

    export default {
        name: 'Home',
        metaInfo: {
            title: 'Startpagina'
        },
        methods: {
            ...mapMutations([
                'setSearchText'
            ]),
            redirectToMenuItem(menuItem) {
                let url = '';
                if (menuItem.bookmark === null) {
                    url = menuItem.Url;
                    if (this.isExternal(url) || url.includes('https://')) {
                        window.open(url);
                    } else {
                        window.open('../' + url);
                    }
                } else {
                    let url = menuItem.bookmark_view_type.Url;
                    url = url.replace('[basisURL]', '../');
                    url = url.replace('[bookmark]', menuItem.bookmark.Naam);
                    window.open(url);
                }
            },
            async loadMenu() {
              const response = await MenuService.all()

              this.css = response.data[0].css
              if(this.css !== undefined && this.css !== null) {
                let file = document.createElement('link');
                file.rel = 'stylesheet';
                file.type = 'text/css'
                file.onload = () => {
                  this.menuData = response.data
                  this.selectedValue = this.menuData[0].ID
                  this.curTag = this.menuData[0].ID

                  this.getActiveTab()
                }
                file.href = this.css + '?nd=' + new Date().getTime()
                document.head.appendChild(file)
              } else {
                this.menuData = response.data
                this.selectedValue = this.menuData[0].ID
                this.curTag = this.menuData[0].ID
              }

              this.getActiveTab()
            },
            isExternal(url) {
                const domain = function (url) {
                    return url.replace('http://', '').replace('https://', '').split('/')[0];
                };

                return domain(location.href) !== domain(url);
            },
            getActiveTab() {
              let params = new URLSearchParams(document.location.search.substring(1));
              let tab = params.get("tab");

              if(tab !== null) {
                this.menuData.map( (menu) => {
                   if(menu.Tab === tab) {
                     this.curTag = menu.ID
                   }
                })
              }
            },
            clearSearchText() {
                this.setSearchText('')
            },
            checkMenuDataDefined() {
                return this.menuData !== undefined && this.menuData !== null && this.menuData.length > 0
            },
            getBackground(menuItem) {
                if (menuItem.Afbeelding !== null) {
                    return '../admin/rest/kaartviewerapi/menu/item/' + menuItem.ID + '/background';
                }
                return '';
            },
            onChange() {
                this.curTag = this.selectedValue
            },
        },
        created() {
            // this.loadMenu();
        },
        computed: {
            ...mapGetters([
                'getSearchText',
                'getFooterAnnouncement'
            ]),
            getMenu() {
                return this.menuData
            },
            getSelectedMenu() {
                if (this.checkMenuDataDefined()) {
                    return this.menuData.find(menu => menu.ID === this.curTag)
                }
                return {}
            },
            getMenuItems() {
                if (this.checkMenuDataDefined()) {
                    if (this.getSearchText !== '') {
                        const menuItemsList = []
                        this.menuData.map((menu) => {
                            Array.prototype.push.apply(menuItemsList, menu.menu_items.filter(menuItem => menuItem.Titel.toLowerCase().indexOf(this.getSearchText.toLowerCase()) > -1))
                        })
                        return menuItemsList
                    }
                    if (this.curTag > 0) {
                        const selectedMenu = this.menuData.find(menu => menu.ID === this.curTag)
                        return selectedMenu.menu_items
                    }

                    const menuItemsList = []
                    this.menuData.map((menu) => {
                        Array.prototype.push.apply(menuItemsList, menu.menu_items)
                    })
                    return menuItemsList
                }
                return []
            },
            getThemeItems() {
                if (this.checkMenuDataDefined()) {
                    const menuItemsList = []
                    this.menuData.map((menu) => {
                        Array.prototype.push.apply(menuItemsList, menu.theme_items)
                    });
                    return menuItemsList
                }
                return []
            }
        },
        data: () => ({
            menuData: [],
            selectedMenu: {},
            curTag: 0,
            selectedValue: 0,
            css: null
        })
    }
</script>

<style>
    .layout-home {
        margin-bottom: 135px;
        margin-top: 45px;
        background-color: transparent !important
    }

    @media (max-width: 991px) {
      .layout-home {
        background-color: white !important;
        padding:5px 10px 10px 10px;
      }
    }

    @media (min-width: 992px) {
        .layout-home {
            margin-bottom: 90px;
            margin-top: 40px;
        }
    }
</style>
