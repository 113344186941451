<template>
    <nav class="layout-footer footer cursor-pointer" :class="getLayoutFooterBg()"
         v-if="getFooterAnnouncement !== null && isHidden === false" @click="isHidden = true"
         title="Mededeling verbergen">
        <div class="container-fluid text-center py-3 pr-3 pl-3">
            <div class="list-group-item list-group-item-action media d-flex align-items-center">
                <div class="ui-icon ui-icon-sm ion ion-md-notifications-outline bg-danger border-0 text-white"></div>
                <div class="media-body line-height-condenced ml-3">
                    <div class="text-body font-weight-bold" v-html="getFooterAnnouncement.Data.Title"></div>
                    <div class="mt-1 font-weight-bold" v-html="getFooterAnnouncement.Data.Message"></div>
                    <div class="text-light small mt-1"><i class="fa fa-clock"></i> {{getFooterAnnouncement.created_at}}
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'app-layout-footer',
        data() {
            return {
                isHidden: false
            }
        },
        methods: {
            getLayoutFooterBg() {
                return `bg-${this.layoutFooterBg}`
            }
        },
        computed: {
            ...mapGetters([
                'getFooterAnnouncement'
            ])
        }
    }
</script>

<style scoped>
    @media (min-width: 768px) {
        .footer {
            position: fixed;
            bottom: 50px !important;
            left: 0;
            right: 0;
            margin: auto;
            width: 40%;
            border-radius: 10px;
        }

        .list-group-item {
            border-radius: 10px !important;
        }
    }
</style>
